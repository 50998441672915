import styled, { css } from 'styled-components';
import React from 'react';
import { breakpoints, colors, fonts } from '../../constants/variables';
import { mix, transparentize } from 'polished';
import { useGameStore } from '../../stores/gameStore';
import FactionButton from './FactionButton';
import { usePlayerStore } from '../../stores/playerStore';
import ScoreIndicator from './ScoreIndicator';

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  @media only screen and (max-width: ${breakpoints.lg}) {
    width: 100%;
    justify-self: start;
  }
`;

const FlexWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  gap: 0.2rem;
  justify-content: center;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 100%;

  @media only screen and (max-width: ${breakpoints.md}) {
    justify-content: flex-start;
  }
`;

const ScoreButtons = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
`;

const StyledSelect = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  opacity: 0;
  color: ${colors.white};
  pointer-events: ${(props) => (props.showScoring ? 'none' : null)};

  &:focus {
    background-color: rgba(0, 0, 0, 0.5);

    + div {
      ${(props) => {
        switch (props.type) {
          case 'stage_1':
            return css`
              box-shadow: 0 0 0 4px ${transparentize(0.5, colors.stage_1)};
            `;
          case 'stage_2':
            return css`
              box-shadow: 0 0 0 4px ${transparentize(0.5, colors.stage_2)};
            `;
          default:
            return css`
              box-shadow: 0 0 0 4px ${transparentize(0.5, colors.secret)};
            `;
        }
      }}
    }
  }
`;

const Div = styled.div`
  color: ${colors.white};
  flex: 1;
  text-align: center;
  ${(props) => {
    switch (props.type) {
      case 'classified':
        return css`
          border: solid 2px ${colors.secret};
          background-color: ${props.selected === ''
            ? mix(0.66, colors.cardBack, colors.secret)
            : colors.cardBack};
        `;
      default:
        return css`
          border: solid 2px
            ${props.value === 1 ? colors.stage_1 : colors.stage_2};
          background-color: ${props.selected === ''
            ? mix(
                0.66,
                colors.cardBack,
                props.value === 1 ? colors.stage_1 : colors.stage_2
              )
            : colors.cardBack};
        `;
    }
  }}
  padding: ${(props) => (props.selected ? '1rem 1rem 2.25rem' : '1rem')};
  pointer-events: ${(props) => (props.showScoring ? null : 'none')};
  font-family: ${fonts.standard};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.selected !== '' ? 'space-between' : 'center'};
  align-items: center;
  border-radius: 0.5rem;
  transition: box-shadow 0.3s, opacity 0.3s;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  @media only screen and (max-width: ${breakpoints.lg}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: ${(props) =>
      props.selected === '' ? 'center' : 'flex-start'};
    position: relative;
    min-height: initial;
    height: auto;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    padding: ${(props) =>
      props.selected ? '0.5rem 0.5rem 2.25rem' : '0.5rem'};
  }
`;

const Flex = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.sm}) {
    padding: 0 1rem 0 0.5rem;
    height: 100%;
  }
`;

const Stage = styled.img`
  min-height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  right: 0.5rem;
  top: 0.5rem;
`;

const CardBack = styled.img`
  height: 80px;
  width: 80px;

  @media only screen and (max-width: ${breakpoints.lg}) {
    height: 50px;
    width: 50px;
  }
`;

const H1 = styled.h1`
  font-family: ${fonts.standard};
  color: ${(props) =>
    mix(
      0.25,
      props.value === 1
        ? props.type === 'stage_1'
          ? colors.stage_1
          : colors.secret
        : colors.stage_2,
      colors.white
    )};
  pointer-events: none;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
`;

const Text = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 2;
  text-shadow: 0 0 0.25rem ${colors.black};

  @media only screen and (max-width: ${breakpoints.lg}) {
    justify-content: flex-start;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.lg}) {
    font-size: 1.25rem;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`;

const RedTape = styled.div`
  background-color: ${colors.error};
  height: 1.75rem;
  width: calc(100% - 4px);
  position: absolute;
  left: 2px;
  bottom: 2px;
  border-radius: 0 0 0.35rem 0.35rem;
  opacity: 0.75;
`;

const POK = styled.img`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  width: 15px;
  z-index: 1;
`;

const ObjectiveCard = ({
  options,
  index,
  disabled,
  type,
  incentivized,
  showScoring,
  scorable,
  maxAllowed,
  count,
}) => {
  const [selectObjective, objectives, variants] = useGameStore((state) => [
    state.selectObjective,
    state.objectives,
    state.variants,
  ]);
  const [players, playerCount, playerUpdateClassified] = usePlayerStore(
    (state) => [state.players, state.playerCount, state.playerUpdateClassified]
  );

  // what type of objective list should we show?
  const typeList = type === 'classified' ? 2 : type === 'stage_1' ? 0 : 1;

  // what is the actual text to display?
  const value = options[0].value;

  // pick the objective from the list
  let objective = objectives[typeList].list[index];

  // basically, is it selected?
  let selected = '';
  let objNames = objectives[typeList].list
    // eslint-disable-next-line array-callback-return
    .filter(function (obj) {
      if (obj) return obj.name;
    })
    .map(function (obj) {
      return obj.name;
    });
  if (objective) {
    selected = objective.name;
  } else {
    selected = '';
  }

  // determines whether we will show the scoring buttons or not
  let showScoreIndicators = false;

  // if the Incentive Program agenda has been played, we show score indicators on the last objective of the incentivized stage
  if (incentivized) {
    if (index === count - 1) {
      showScoreIndicators = true;
    } else {
      switch (type) {
        case 'stage_1':
          showScoreIndicators = maxAllowed >= index;
          break;
        case 'stage_2':
          showScoreIndicators = maxAllowed - 1 > index;
          break;
        default:
          showScoreIndicators = false;
      }
    }
  } else {
    switch (type) {
      case 'stage_1':
        showScoreIndicators = maxAllowed - 1 >= index;
        break;
      case 'stage_2':
        showScoreIndicators = maxAllowed - 1 >= index;
        break;
      case 'classified':
        showScoreIndicators = true;
        break;
      default:
        showScoreIndicators = false;
    }
  }

  const handleObjective = (value, index, type) => {
    selectObjective(value, index, type);
    if (type === 'classified') {
      const classifiedPlayer = players.filter((player) => {
        return player.scored_objectives.classified[0] === '';
      });
      if (classifiedPlayer.length > 0) {
        playerUpdateClassified(classifiedPlayer[0].id, value);
      }
    }
  };
  return (
    <Block>
      <SelectWrapper>
        <StyledSelect
          type={type}
          disabled={disabled}
          showScoring={showScoring}
          onChange={(e) => handleObjective(e.target.value, index, type)}
        >
          <option value="">Select an Objective</option>
          {options.map((option) => {
            return (
              <option
                key={option.name}
                value={option.name}
                disabled={objNames.includes(option.name)}
              >
                {option.name}
              </option>
            );
          })}
        </StyledSelect>
        {selected && objective.expansion && (
          <POK src="/pok.svg" alt="Prophecy of Kings Objective" />
        )}
        <Div
          selected={selected}
          value={options[0].value}
          type={type}
          disabled={disabled}
          showScoring={showScoring}
        >
          {selected &&
            objective &&
            (variants.redTape
              ? !showScoreIndicators || !showScoring
              : !showScoring) && (
              <Flex>
                <H1 value={value} type={type}>
                  {selected}
                </H1>
                <Text>
                  <P>{objective.text}</P>
                </Text>
                {variants.redTape && !scorable && <RedTape />}
              </Flex>
            )}
          {selected && objective && showScoring && showScoreIndicators && (
            <Flex>
              <H1 value={value} type={type}>
                {selected}
              </H1>
              <ScoreButtons playerCount={playerCount}>
                {players.map((player) => {
                  return (
                    <FactionButton
                      key={player.faction.id}
                      id={player.id}
                      name={player.faction.name}
                      icon={player.faction.icon}
                      color={player.faction.bg_color}
                      count={playerCount}
                      selected={selected}
                      scored={player.scored_objectives[type]}
                      type={type}
                    />
                  );
                })}
              </ScoreButtons>
            </Flex>
          )}
          {selected ? (
            <Stage
              value={value}
              src={
                value === 1
                  ? type === 'stage_1'
                    ? '/onepoint.svg'
                    : '/onepoint_secret.svg'
                  : '/twopoints.svg'
              }
              alt=""
            />
          ) : (
            <CardBack
              value={value}
              alt=""
              src={
                value === 1
                  ? type === 'stage_1'
                    ? '/stage_1.svg'
                    : '/secret.svg'
                  : '/stage_2.svg'
              }
            />
          )}
          {selected && showScoreIndicators && (
            <FlexWrapper>
              {players.map((player) => {
                return (
                  <ScoreIndicator
                    key={player.faction.id}
                    id={player.id}
                    name={player.faction.name}
                    icon={player.faction.icon}
                    color={player.faction.bg_color}
                    count={playerCount}
                    selected={selected}
                    scored={player.scored_objectives[type]}
                    type={type}
                  />
                );
              })}
            </FlexWrapper>
          )}
        </Div>
      </SelectWrapper>
    </Block>
  );
};

export default ObjectiveCard;
